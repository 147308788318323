$(function () {
	$('[data-toggle="tooltip"]').tooltip();
	$('[data-toggle="popover"]').popover();

	$('input.datepicker').datepicker({
		calendarWeeks: true,
		autoclose: true,
		todayHighlight: true,
		format: 'yyyy-mm-dd'
	});

	/* DataTables
	 ---------------------------------------- */

	datatable = $('table.datatable').DataTable({
		aaSorting: [],
		bStateSave: true,
		iDisplayLength: 10,
		lengthMenu: [[10, 25, 50, -1], [10, 25, 50, 'Alle']]
	});
});